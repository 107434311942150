import React, { Component } from 'react';

class Search extends Component {
    render() {
        return (
            <div>
                search
            </div>
        );
    }
}

export default Search;